<template>
  <div>
    <b-button :disabled="disabled" class="w-100" v-b-modal.filters-modal>
      Advanced Filters
    </b-button>

    <b-modal id="filters-modal" class="data-entry" centered scrollable size="xl">
      <template #modal-title>
        <h3 class="mb-0">Advanced Filters</h3>
      </template>

      <div class="row">
        <div class="col-md-6 mb-4">
          <label for="">Country / Region</label>

          <ifac-search-dropdown
            :options="countries"
            placeholder="Select Country / Region"
            :current="currentSelectedCountry"
            currentType="object"
            currentValue="name"
            optionValue="code"
            optionTitle="name"
            searchAgainst="name"
            emitType="string"
            emitValue="code"
            showClear
            @selected="filters.countries = $event"
          ></ifac-search-dropdown>
        </div>

        <div class="col-md-6 mb-4">
          <label for="">Industry / Academia / Government</label>
          <ifac-search-dropdown
            :options="iags"
            placeholder="Select Industry / Academia / Government"
            :current="currentSelectedIag"
            currentType="object"
            currentValue="name"
            optionValue="name"
            optionTitle="name"
            searchAgainst="name"
            emitType="string"
            emitValue="name"
            showClear
            @selected="filters.iags = $event ? $event.toLowerCase() : ''"
          ></ifac-search-dropdown>
        </div>
      </div>

      <!--  -->

      <fields-of-interest
        :selected="filters.fieldsOfInterest"
        @selected="toggleInterest"
      ></fields-of-interest>

      <!--  -->

      <template #modal-footer="{ close }">
        <div class="w-100">
          <b-button
            variant="light"
            class="float-left"
            @click="initFilters(close)"
          >
            Clear Filters
          </b-button>

          <b-button
            variant="primary"
            class="float-right"
            @click="applyFilters(close)"
          >
            Apply
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { IfacSearchDropdown } from '@ifac/ui';
import { mapGetters } from 'vuex';
import FieldsOfInterest from './filtering-partials/FieldsOfInterest.vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    currentFilters: {
      type: Object,
      default: () => ({
        countries: '',
        iags: '',
        fieldsOfInterest: [],
      }),
    },
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      iags: 'industryAcademiaGovernment/data',
    }),
    filters() {
      return this.currentFilters;
    },
    currentSelectedCountry() {
      return this.countries.find((i) => i.code === this.currentFilters.countries);
    },
    currentSelectedIag() {
      return this.iags.find((i) => i.id === this.currentFilters.iags);
    },
  },
  methods: {
    initFilters(close) {
      this.$emit('initFilters', close);
    },
    toggleInterest(interest) {
      if (!this.filters.fieldsOfInterest) {
        this.filters.fieldsOfInterest = [];
      }
      const exists = this.filters.fieldsOfInterest.findIndex((i) => i.id === interest.id);
      if (exists > -1) {
        this.filters.fieldsOfInterest.splice(exists, 1);
      } else {
        this.filters.fieldsOfInterest.push(interest);
      }
    },
    applyFilters(close) {
      this.$emit('filtersSet', this.filters);

      close();
    },
  },
  components: {
    IfacSearchDropdown,
    FieldsOfInterest,
  },
};
</script>

<style scoped>
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: white !important;
  border: 1px solid #D8D8D8;
}
</style>
