<template>
  <div>
    <h6 class="text-dark font-weight-bold mb-3">Fields of interest</h6>
    <div class="row" v-if="coordinatingCommittees.length > 0">
      <div class="col">
        <div class="row">
          <div
            class="col-md-4 mb-4"
            v-for="cc in coordinatingCommittees"
            :key="cc.id"
          >
            <h6 class="text-primary font-weight-bold">
              CC {{ cc.number }} - {{ cc.name }}
            </h6>

            <div
              class="form-check"
              v-for="tc in cc.technicalCommittees"
              :key="'tc-' + tc.id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :checked="
                  selected.find(
                    (i) => i.number === tc.number && i.ccNumber === tc.ccNumber
                  )
                "
                @change="selectTechnicalCommittee(tc)"
                :id="'tc-check' + tc.id"
              />
              <label class="form-check-label" :for="'tc-check' + tc.id">
                <span>TC {{ cc.number }}.{{ tc.number }} {{ tc.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ifac-loader
      v-if="coordinatingCommittees.length === 0"
      class="my-5"
    ></ifac-loader>
  </div>
</template>

<script>
import { IfacLoader } from '@ifac/ui';
import CoordinatingCommittees from '@/services/Api/CoordinatingCommittees';

export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      coordinatingCommittees: [],
    };
  },
  methods: {
    selectTechnicalCommittee(tc) {
      this.$emit('selected', tc);
    },
    async fetchCoordinatingCommittees() {
      const {
        data: { data },
      } = await CoordinatingCommittees.all();
      this.coordinatingCommittees = data;
    },
  },
  created() {
    this.fetchCoordinatingCommittees();
  },
  components: {
    IfacLoader,
  },
};
</script>

<style>
</style>
