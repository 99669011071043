<template>
  <b-input-group
    class="filters-search d-sm-flex"
    :class="{ 'focus': searchFocus }"
  >
    <b-input-group-prepend class="align-items-center pl-3">
      <font-awesome-icon
        :icon="['fal', 'search']"
        class="text-primary"
        @focus="onSearchFocus"
        @blur="onSearchBlur"
      >
      </font-awesome-icon>
    </b-input-group-prepend>
    <b-form-input
      placeholder="Search"
      @input="$emit('input', $event)"
      :value="value"
      :disabled="disabled"
      @focus="onSearchFocus"
      @blur="onSearchBlur"
      ref="searchInput"
      class=""
    ></b-form-input>
  </b-input-group>
</template>

<script>
export default {
  name: 'SearchFilter',
  props: {
    showSearch: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchFocus: false,
    };
  },
  methods: {
    onSearchFocus() {
      this.searchFocus = true;
      this.$refs.searchInput.$refs.input.focus();
    },
    onSearchBlur() {
      this.searchFocus = false;
    },

  },
  watch: {
    showSearch() {
      this.$nextTick(() => {
        this.$refs.searchInput.$refs.input.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/_variables.scss';

.filters-search {
  border: 1px solid $color-grey;

  .form-control {
    border: none;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .input-group-prepend {
    color: $color-black-lighter;
  }
}
</style>
